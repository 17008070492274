import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button } from '@material-ui/core';

// import SnackbarMessage from '../../../../../common/snackbarMessage';
import Spinner from '../../../../../common/spinner';
import * as types from '../../../../../actions/types';
import * as teamTypes from '../../../../../actions/teamTypes';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../../userOnBoarding/actions/actions';
import {
  irctcOrderPay,
  irctcPaymentConfirm,
  setRazorpayResponse,
  accessTokenForGuestUser,
  getIrctcCardList,
} from '../../../actions/report';
import { isNull } from '../../../../../utils/helper';
import '../../../styles/form/guestSummary.css';
import withRouter from 'withRouter';

interface IState {
  imgReq: boolean;
  noGuestAccessToken: boolean;
}

interface IProps {
  blobUrl: any;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  location: any;
  history: any;
  irctcOrderPay: (instanceIds: any, publicId: string) => void;
  irctcOrderPayRequest: boolean;
  irctcOrderPaySuccess: boolean;
  irctcOrderPayDetails: any;
  irctcPayConfirmRequest: boolean;
  irctcPayConfirmSuccess: boolean;
  irctcPayConfirmDetails: any;
  irctcPaymentConfirm: (
    publicId: string,
    instanceIds: any,
    orderId: string,
    transactionId: string,
    razorpaySignature: string
  ) => void;
  setRazorpayResponse: (data: any) => void;
  teamDetails: any;
  teamDetailsSuccess: boolean;
  teamDetailsFailure: boolean;
  guestUserAccessTokenSuccess: boolean;
  accessTokenForGuestUser: (teamId: string, fromGuestUser: boolean) => void;
  guestUserAccessTokenFailure: boolean;

  irctcCardListReq: boolean;
  irctcCardListSuccess: boolean;
  irctcCardList: any;
  getIrctcCardList: (desigId: string) => void;
  router: any;
}

class Summary extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      imgReq: true,
      noGuestAccessToken: false,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    this.props.accessTokenForGuestUser(this.props.router.params.publicId, true);
  }

  componentDidUpdate() {
    if (this.props.guestUserAccessTokenSuccess) {
      this.props.dispatch({
        type: types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
      });
      this.props.getIrctcCardList(this.props.router.params.desigId);
    }

    if (this.props.guestUserAccessTokenFailure) {
      this.props.dispatch({
        type: types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
      });
      this.setNoGuestAccessToken();
    }

    if (this.props.irctcOrderPaySuccess) {
      this.props.dispatch({
        type: types.RESET_IRCTC_ORDER_PAY_REQUEST,
      });
      this.openRazorPayModal();
    }
    if (this.props.irctcPayConfirmSuccess) {
      this.props.dispatch({
        type: types.RESET_IRCTC_PAYMENT_CONFIRM_REQUEST,
      });
      this.props.router.navigate(
        `/p/irctc/smartid/${this.props.router.params.publicId}/${this.props.router.params.desigId}/success`
      );
    }
    if (this.props.teamDetailsSuccess || this.props.teamDetailsFailure) {
      this.props.dispatch({
        type: teamTypes.RESET_GET_TEAM_DETAILS_REQUEST,
      });
      this.resetImgReq();
    }
    if (this.props.irctcCardListSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_IRCTC_CARD_LIST_REQUEST,
      });
    }
  }

  resetImgReq = () => {
    this.setState({ imgReq: false });
  };

  setNoGuestAccessToken = () => {
    this.setState({ noGuestAccessToken: true });
  };

  getInstanceIds = () => {
    const ids: any = [];
    if (!isNull(this.props.irctcCardList)) {
      this.props.irctcCardList.map((item: any) => {
        if (!isNull(item._mo_instance_id)) ids.push(item._mo_instance_id);
      });
    }
    return ids;
  };

  callPaymentConfirm = (response: any) => {
    const data = {
      publicId: this.props.router.params.publicId,
      instanceIds: this.getInstanceIds(),
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
      signature: response.razorpay_signature,
    };
    this.props.setRazorpayResponse(data);
    this.props.irctcPaymentConfirm(
      data.publicId,
      data.instanceIds,
      data.orderId,
      data.paymentId,
      data.signature
    );
  };

  openRazorPayModal = () => {
    const options = {
      key: process.env.REACT_APP_MO_RZP_KEY_ID,
      order_id: this.props.irctcOrderPayDetails.id,
      handler: (response: any) => this.callPaymentConfirm(response),
      prefill: {
        name: '',
        email: '',
        contact: '',
      },
      theme: {
        color: '#528ff0',
      },
    };

    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
  };

  payNow = () => {
    this.props.irctcOrderPay(
      this.getInstanceIds(),
      this.props.router.params.publicId
    );
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  catalogItem = (item: any, index: number) => {
    return (
      <div
        key={item['instance_id'] || index}
        className="gsummary-catalog-item-wrapper"
      >
        <div className="gsummary-code-name-wrapper">
          <p>
            {item.name_of_staff_member}/{item.staff_mobile_no}
          </p>
          <p className="price">{`Rs 100`}</p>
        </div>
        <div className="gsummary-price-wrapper">
          <p>
            {`Designation: `}
            <span>{`${item.designation}`}</span>
          </p>
        </div>
      </div>
    );
  };

  renderNoGuestAccessToken = () => {
    return (
      <p className="no-access-token" style={{ textAlign: 'center' }}>
        You do not have permission
      </p>
    );
  };

  renderLogo = () => {
    if (this.state.imgReq) return null;
    else if (
      this.props.teamDetails &&
      this.props.teamDetails.logo1 &&
      this.props.teamDetails.logo1.blobUrl
    )
      return (
        <img
          alt="logo"
          className="logo"
          src={this.props.teamDetails.logo1.blobUrl}
        />
      );
    else
      return (
        <img
          src={require('./../../../../../assets/images/userOnBoarding/logo-big.png')}
          alt="logo"
          className="logo"
        />
      );
  };

  render() {
    return (
      <div id="guest-summary-root">
        <div className="guest-summary-wrapper">
          <div
            className="irctc-user-logo-wrapper"
            style={{ textAlign: 'center' }}
          >
            {this.renderLogo()}
          </div>
          <div
            className="guest-summary-header"
            style={{ justifyContent: 'center' }}
          >
            <p>Smart ID Card</p>
          </div>
          {(this.props.irctcOrderPayRequest ||
            this.props.irctcPayConfirmRequest) && <Spinner />}
          {this.state.noGuestAccessToken ? (
            this.renderNoGuestAccessToken()
          ) : (
            <div className="guest-summary-content">
              <div className="guest-summary-content-wrapper">
                <div
                  className="name-sku-list-wrapper"
                  style={{ margin: '0 auto' }}
                >
                  <div
                    className="sku-list-title-wrapper"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="sku-list-wrapper">
                      <p className="title">
                        <i
                          className="fas fa-check"
                          style={{ paddingRight: '10px', color: '#2AC940' }}
                        />
                        Shopping Cart:{' '}
                        {!isNull(this.props.irctcCardList)
                          ? this.props.irctcCardList.length
                          : 0}{' '}
                        Items Selected
                      </p>
                      {!isNull(this.props.irctcCardList) &&
                        this.props.irctcCardList.map(
                          (item: any, index: number) => {
                            return this.catalogItem(item, index);
                          }
                        )}
                    </div>
                  </div>
                  <div
                    className="price-details-root"
                    style={{ width: '100%', marginTop: 20 }}
                  >
                    <div className="price-details-wrapper">
                      <div className="account-details-wrapper">
                        <div className="value-wrapper">
                          <h5>
                            Subtotal (
                            {!isNull(this.props.irctcCardList)
                              ? this.props.irctcCardList.length
                              : 0}{' '}
                            Items )
                          </h5>
                        </div>
                        <div className="value-wrapper">
                          <h5>Total Amount</h5>
                          <h5>
                            Rs{' '}
                            {!isNull(this.props.irctcCardList)
                              ? this.props.irctcCardList.length * 100
                              : 0}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrapper">
                      <Button
                        className={`form-update-button ${
                          this.props.irctcOrderPayRequest ||
                          isNull(this.props.irctcCardList)
                            ? 'disabled-button'
                            : ''
                        }`}
                        disabled={isNull(this.props.irctcCardList)}
                        onClick={this.payNow}
                      >
                        PAY
                      </Button>
                    </div>
                    <div className="support-wrapper">
                      <p>
                        For support/help, go to{' '}
                        <a
                          href="https://mobileoffize.com/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          www.mobileoffize.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage || this.props.success.displayMessage
          }
          open={this.props.open}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    blobUrl: state.reportReducer.blobUrl,

    guestUserAccessTokenSuccess:
      state.reportReducer.guestUserAccessTokenSuccess,
    guestUserAccessTokenFailure:
      state.reportReducer.guestUserAccessTokenFailure,

    irctcOrderPayRequest: state.reportReducer.irctcOrderPayRequest,
    irctcOrderPaySuccess: state.reportReducer.irctcOrderPaySuccess,
    irctcOrderPayDetails: state.reportReducer.irctcOrderPayDetails,

    irctcPayConfirmRequest: state.reportReducer.irctcPayConfirmRequest,
    irctcPayConfirmSuccess: state.reportReducer.irctcPayConfirmSuccess,
    irctcPayConfirmDetails: state.reportReducer.irctcPayConfirmDetails,

    teamDetails: state.reportReducer.teamDetails,
    teamDetailsSuccess: state.reportReducer.teamDetailsSuccess,
    teamDetailsFailure: state.reportReducer.teamDetailsFailure,

    irctcCardListReq: state.reportReducer.irctcCardListReq,
    irctcCardListSuccess: state.reportReducer.irctcCardListSuccess,
    irctcCardList: state.reportReducer.irctcCardList,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      irctcOrderPay,
      irctcPaymentConfirm,
      setRazorpayResponse,
      accessTokenForGuestUser,
      getIrctcCardList,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Summary));
