import * as types from '../../../actions/types';
import * as teamTypes from '../../../actions/teamTypes';

export function getAllMyReports(resolve: () => void) {
  return {
    type: types.GET_ALL_MY_REPORTS_REQUEST,
    payload: {
      resolve,
    },
  };
}

export function getInboxData(templateId: any, instanceIds: any) {
  return {
    type: types.GET_INBOX_DATA_REQUEST,
    payload: {
      templateId,
      instanceIds,
    },
  };
}

export function getReportDetails(
  templateId: string,
  teamId: string,
  resolve: () => void
) {
  return {
    type: types.GET_REPORT_DETAILS_REQUEST,
    payload: {
      templateId,
      teamId,
      resolve,
    },
  };
}

export function getFormDataDetails(
  templateId: string,
  instanceId: string,
  fields: [],
  isFromForm = false
) {
  return {
    type: types.GET_FORM_DATA_DETAILS_REQUEST,
    payload: {
      templateId,
      instanceId,
      fields,
      isFromForm,
    },
  };
}

export function getRatingFormDataDetails(
  templateId: string,
  instanceId: string,
  publicId: string,
  fields: [],
  isFromForm = false
) {
  return {
    type: types.GET_RATING_FORM_DATA_DETAILS_REQUEST,
    payload: {
      templateId,
      instanceId,
      publicId,
      fields,
      isFromForm,
    },
  };
}

export function createFormData(
  templateId: string,
  viewName: string,
  formData: any,
  isImagePicked = false,
  isFromGuestUser = false
) {
  return {
    type: types.CREATE_FORM_DATA_REQUEST,
    payload: {
      templateId,
      viewName,
      formData,
      isImagePicked,
      isFromGuestUser,
    },
  };
}

export function updateFormData(
  templateId: string,
  instanceId: string,
  viewName: string,
  formData: any,
  isImagePicked = false
) {
  return {
    type: types.UPDATE_FORM_DATA_REQUEST,
    payload: {
      templateId,
      instanceId,
      viewName,
      formData,
      isImagePicked,
    },
  };
}

export function getLookupInstanceIds(
  sourceTemplateId: string,
  referenceTemplateId: string,
  fieldInternalName: any,
  query: any,
  resolve: () => void
) {
  return {
    type: types.GET_LOOKUP_INSTANCEIDS_REQUEST,
    payload: {
      sourceTemplateId,
      referenceTemplateId,
      fieldInternalName,
      query,
      resolve,
    },
  };
}

export function getExternalLookupData(
  templateId: string,
  instanceIds: string,
  resolve: () => void
) {
  return {
    type: types.GET_EXTERNAL_LOOKUP_DATA_REQUEST,
    payload: {
      templateId,
      instanceIds,
      resolve,
    },
  };
}

export function getCatalogData(catalogDetails: any) {
  return {
    type: types.GET_CATALOG_DATA_REQUEST,
    payload: {
      catalogDetails,
    },
  };
}

export function getInboxInstanceIds(
  templateId: any,
  query: any,
  filterQuery: any
) {
  return {
    type: types.GET_INBOX_INSTANCEIDS_REQUEST,
    payload: {
      templateId,
      query,
      filterQuery,
    },
  };
}

export function setExtProps(externalProps: any) {
  return {
    type: types.SET_EXTPROPS,
    payload: {
      externalProps,
    },
  };
}

export function getUnassignedData(
  templateId: string,
  query: any,
  stageName: string,
  offset: any,
  pageSize: any
) {
  return {
    type: types.GET_UNASSIGNED_DATA_REQUEST,
    payload: {
      templateId,
      query,
      stageName,
      offset,
      pageSize,
    },
  };
}

export function updateAssignee(details: any) {
  return {
    type: types.UPDATE_ASSIGNEE_REQUEST,
    payload: {
      details,
    },
  };
}

export function getAllInboxConfigs(templateId: string) {
  return {
    type: types.GET_ALL_INBOX_CONFIGS_REQUEST,
    payload: {
      templateId,
    },
  };
}

export function getInboxViewsData(
  templateId: string,
  tabDetails: any,
  filterQuery: any,
  searchText: string,
  pageNumber: any,
  pageSize: any
) {
  return {
    type: types.GET_INBOX_VIEWS_DATA_REQUEST,
    payload: {
      templateId,
      tabDetails,
      filterQuery,
      searchText,
      pageNumber,
      pageSize,
    },
  };
}

export function setInboxTotalCount(totalCount: any) {
  return {
    type: types.SET_INBOX_VIEWS_TOTAL_COUNT,
    payload: {
      totalCount,
    },
  };
}

export function setSelectedInboxView(inboxView: any) {
  return {
    type: types.SET_SELECTED_INBOX_VIEW,
    payload: {
      inboxView,
    },
  };
}

export function setInboxSearchText(searchText: any) {
  return {
    type: types.SET_INBOX_SEARCH_TEXT,
    payload: {
      searchText,
    },
  };
}

export function setActiveUnassignedStage(unassignedActiveStage: any) {
  return {
    type: types.SET_ACTIVE_UNASSIGNED_STAGE,
    payload: {
      unassignedActiveStage,
    },
  };
}

export function setUnassignedSearchText(unassignedSearchText: any) {
  return {
    type: types.SET_UNASSIGNED_SEARCH_TEXT,
    payload: {
      unassignedSearchText,
    },
  };
}

export function setSelectedFieldValue(open: boolean, message: string) {
  return {
    type: types.SET_SELECTED_FIELD_VALUE,
    payload: {
      open,
      message,
    },
  };
}

export function createVendorOnboardingForm(formData: any) {
  return {
    type: types.CREATE_VENDOR_ONBOARDING_FORM_REQUEST,
    payload: {
      formData,
    },
  };
}

export function handleImagesUploadAndDownload(
  templateId: string,
  teamId: string,
  instanceId: string,
  fieldInternalName: string,
  doc: any,
  actionType: any,
  resolve: any,
  reject: any
) {
  return {
    type: types.HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_REQUEST,
    payload: {
      templateId,
      teamId,
      instanceId,
      fieldInternalName,
      doc,
      actionType,
      resolve,
      reject,
    },
  };
}

export function handleDocumentUpload(
  teamId: string,
  templateId: string,
  instanceId: string,
  doc: any,
  resolve: any,
  reject: any
) {
  return {
    type: types.HANDLE_DOCUMENT_UPLOAD_REQUEST,
    payload: {
      teamId,
      templateId,
      instanceId,
      doc,
      resolve,
      reject,
    },
  };
}

export function accessTokenForGuestUser(
  publicId: string,
  fromGuestUser = false
) {
  return {
    type: types.ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
    payload: {
      publicId,
      fromGuestUser,
    },
  };
}

export function getOTPForGuestUser(userName: string, loginType: string) {
  return {
    type: types.GET_OTP_FOR_GUEST_USER_REQUEST,
    payload: {
      userName,
      loginType,
    },
  };
}

export function validateGuestUserOTP(
  teamId: string,
  requestId: string,
  otp: number
) {
  return {
    type: types.VALIDATE_GUEST_USER_OTP_REQUEST,
    payload: {
      teamId,
      requestId,
      otp,
    },
  };
}

export function resendGuestUserOTP(requestId: string) {
  return {
    type: types.RESEND_GUEST_USER_OTP_REQUEST,
    payload: {
      requestId,
    },
  };
}

export function getActivityLog(templateId: string, instanceId: string) {
  return {
    type: types.GET_ACTIVITY_LOG_REQUEST,
    payload: {
      templateId,
      instanceId,
    },
  };
}

export function getTeamDesignations(teamId: string) {
  return {
    type: types.GET_TEAM_DESIGNATIONS_REQUEST,
    payload: {
      teamId,
    },
  };
}

export function createNaveenFormData(
  templateId: string,
  formData: any,
  publicId: string
) {
  return {
    type: types.CREATE_NAVEEN_FORM_DATA_REQUEST,
    payload: {
      templateId,
      formData,
      publicId,
    },
  };
}

export function setGuestFormdata(guestFormDetails: any) {
  return {
    type: types.SET_GUEST_FORMDATA,
    payload: {
      guestFormDetails,
    },
  };
}

export function updateNaveenFormData(
  templateId: string,
  instanceId: string,
  formData: any,
  publicId: string
) {
  return {
    type: types.UPDATE_NAVEEN_FORM_DATA_REQUEST,
    payload: {
      templateId,
      instanceId,
      formData,
      publicId,
    },
  };
}

export function updateRatingFormData(
  templateId: string,
  instanceId: string,
  formData: any,
  publicId: string,
  viewName: string
) {
  return {
    type: types.UPDATE_RATING_FORM_DATA_REQUEST,
    payload: {
      templateId,
      instanceId,
      formData,
      publicId,
      viewName,
    },
  };
}

export function orderPay(instanceId: any, publicId: string) {
  return {
    type: types.ORDER_PAY_REQUEST,
    payload: {
      instanceId,
      publicId,
    },
  };
}

export function paymentConfirm(
  publicId: string,
  instanceId: any,
  orderId: string,
  transactionId: string,
  razorpaySignature: string
) {
  return {
    type: types.PAYMENT_CONFIRM_REQUEST,
    payload: {
      publicId,
      instanceId,
      orderId,
      transactionId,
      razorpaySignature,
    },
  };
}

export function setRazorpayResponse(razoResp: any) {
  return {
    type: types.SET_RAZORPAY_RESPONSE,
    payload: {
      razoResp,
    },
  };
}

export function getTeamDetails(teamId: string) {
  return {
    type: teamTypes.GET_TEAM_DETAILS_REQUEST,
    payload: {
      teamId,
    },
  };
}

export function irctcOrderPay(instanceIds: any, publicId: string) {
  return {
    type: types.IRCTC_ORDER_PAY_REQUEST,
    payload: {
      instanceIds,
      publicId,
    },
  };
}

export function irctcPaymentConfirm(
  publicId: string,
  instanceIds: any,
  orderId: string,
  transactionId: string,
  razorpaySignature: string
) {
  return {
    type: types.IRCTC_PAYMENT_CONFIRM_REQUEST,
    payload: {
      publicId,
      instanceIds,
      orderId,
      transactionId,
      razorpaySignature,
    },
  };
}

export function getIrctcCardList(desigId: string) {
  return {
    type: types.GET_IRCTC_CARD_LIST_REQUEST,
    payload: {
      desigId,
    },
  };
}

export function getOTPForForm(
  userName: string,
  loginType: string,
  resolve: any,
  reject: any
) {
  return {
    type: types.GET_OTP_FOR_FORM_REQUEST,
    payload: {
      userName,
      loginType,
      resolve,
      reject,
    },
  };
}

export function getNeProductFilterOptions(
  templateId: string,
  filterQuery: string
) {
  return {
    type: types.GET_NE_PRODUCT_FILTER_OPTIONS_REQUEST,
    payload: {
      templateId,
      filterQuery,
    },
  };
}
